<script>
import { onMounted, onUpdated, computed, reactive, ref } from 'vue'
import { http } from '@/http'
import { useRoute } from 'vue-router'
export default {
  setup() {
    let route = useRoute()
    let successInfo = reactive({})
    let failsInfo = reactive({})
    let shopId = ref('')
    let isSuccess = computed(() => 'icon' in successInfo)
    //let isMobile = computed(() => 'ontouchstart' in document)
    let kefuTel = ref('')
    let isMobile = computed(() => 'ontouchstart' in document)

    function successRequest(data) {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.MerchantAuthService.addShopOrderSourceRef', {
        ...data
      }).then(data => {
        if (data.result) {
          for (let item in data.result) {
            successInfo[item] = data.result[item]
          }
        }
      })
    }
    function authTokenCallback() {
      http.post('/elm/callback/authToken', {
        code: route.query.code,
        state: route.query.state
      }).then(data => {
        shopId.value = decodeURIComponent(route.query.state).split(',')[1]
        if (data.errCode === 0) {
          shopId.value = data.result.shopId
          successRequest(data.result)
        }
        else {
          failsInfo.errName = data.errName
          failsInfo.errCode = data.errCode
          failsInfo.errDesc = data.errDesc
        }
      }).catch(err => { })
    }

    function handleClickNativePath(path) {
      window.location.href = path
    }

    function getKeFuTel(){
      http.post('/?cmd=com.xqxc.api.pigear.biz.platform.KefuTelService.getKefuTel').then(res => {
        if(res.result){
          kefuTel.value = res.result
        }
      })
    }

    onMounted(() => {
      authTokenCallback()
      if(!isMobile.value){
        getKeFuTel()
      }
    })

    onUpdated(() => {
      document.title = 'icon' in successInfo ? '授权成功' : '授权失败'
    })
    return {
      successInfo,
      failsInfo,
      isSuccess,
      shopId,
      handleClickNativePath,
      isMobile,
      kefuTel
    }
  }
}
</script>
<template>
  <div style="text-align:center; padding:1.8rem 1rem">
    <template v-if="isSuccess">
      <p><img style="width:2rem; height:2rem; margin:.3rem 0" :src="successInfo.icon" /></p>
      <p style="font-weight:bold">{{successInfo.appName}}</p>
      <!--     <p>当前版本：{{info.version}}</p>
 -->
      <p style="text-align:left;word-break: break-all; margin-top: .4rem;line-height:1.5">{{successInfo.aboutUs}}</p>
    </template>
    <template v-else>
      <p v-if="failsInfo.errCode"><img style="width:2rem; height:2rem; margin:.3rem 0" src="@/assets/images/icon-fail.png" /></p>
      <p v-if="failsInfo.errName">{{failsInfo.errName}}</p>
      <div class="error-text" v-if="failsInfo.errDesc" style="line-height:1.5">
        <div v-html="failsInfo.errDesc"></div>
        <h1 v-if="failsInfo.errCode === 90004&&!isMobile">客服电话:{{kefuTel}}</h1>
      </div>
    </template>

    <div class="btn-ct" v-if="isMobile">
      <p v-show="failsInfo.errCode === 90002">
        <van-button block @click="handleClickNativePath('lpmerchant://route/pop')" type="primary">重新授权</van-button>
      </p>
      <p v-show="failsInfo.errCode === 90004">
        <van-button block @click="handleClickNativePath('lpmerchant://call/customer/tel')" type="primary">联系客服</van-button>
      </p>
      <!-- <p v-show="failsInfo.errCode === 90005"><van-button block @click="handleClickNativePath('lpmerchant://route/store/add')" type="primary">新增门店</van-button></p> -->
      <p v-show="isSuccess || failsInfo.errCode === 90005">
        <van-button @click="handleClickNativePath('lpmerchant://route/store')" block type="primary">门店管理</van-button>
      </p>
      <p>
        <van-button block type="primary" @click="handleClickNativePath('lpmerchant://route/pop/root')">返回首页</van-button>
      </p>
    </div>

  </div>
</template>
<style scoped>
.btn-ct {
  position: fixed;
  left: 1rem;
  right: 1rem;
  bottom: 0;
}
.btn-ct p {
  margin-bottom: 0.4rem;
}
div.error-text p {
  text-align: left;
}
</style>